<template>
  <v-container fluid>
    <v-container fluid>
      <v-dialog
      v-model="addEventDialog"
      width="500"
      >
        <v-card>
          <v-card-title>
            Create an Event
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="createEventName" name="name" label="Name" type="text"></v-text-field>
            <v-text-field v-model="createEventStartLocalTime" name="event_start_local_time" label="Event Start" single-line>
              <template v-slot:append-outer>
                <global-datePicker v-model="createEventStartLocalTime"/>
              </template>
            </v-text-field>
            <v-text-field v-model="createEventEndLocalTime" name="event_end_local_time" label="Event End" single-line>
              <template v-slot:append-outer>
                <global-datePicker v-model="createEventEndLocalTime"/>
              </template>
            </v-text-field>
            <ul>
              <li v-for="(error, index) in createEventErrors" :key="index" class="red--text">{{ error.error }}</li>
            </ul>
          </v-card-text>
          <v-card-actions>
             <v-btn color="secondary" @click="createNewEvent" :loading="createEventLoading">
              Create
              </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col
          cols="4"
        >
          <v-card
            dark
            outlined
            height="500"
          >
            <v-card-title class="secondary py-1 text-subtitle-1">
              <v-icon left dense> mdi-star </v-icon>
              Groups
              <v-spacer/>
              <v-btn class="mx-1" small>
                <v-icon left small>
                  mdi-plus
                </v-icon>
                Add
              </v-btn>
              <v-btn small>
                <v-icon left small>
                  mdi-close
                </v-icon>
                Delete
              </v-btn>
            </v-card-title>
            <v-card-text class="pa-0">
              <skeletons-table :loading="groupsTableLoading">
                <tables-standard
                  :height=530
                  :headers="groupsTable.headings"
                  :items="groupsTable.data"
                />
              </skeletons-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="8"
        >
          <v-card
            dark
            outlined
            height="500"
          >
            <v-card-title class="secondary py-1 text-subtitle-1">
              <v-icon left dense> mdi-flag </v-icon>
              Events
              <v-spacer/>
              <v-btn class="mx-1" small color="success">
                <v-icon small class="pa-0 ma-0">
                  mdi-plus
                </v-icon>
                link group to event
              </v-btn>
              <v-btn class="mx-1" small color="error">
                <v-icon left small>
                  mdi-plus
                </v-icon>
                unlink group to event
              </v-btn>
              <v-btn class="mx-1" small @click="addEventDialog = true">
                <v-icon left small>
                  mdi-plus
                </v-icon>
                Add
              </v-btn>
              <v-btn small>
                <v-icon left small>
                  mdi-close
                </v-icon>
                Delete
              </v-btn>
            </v-card-title>
            <v-card-text class="pa-0">
              <skeletons-table :loading="eventsTableLoading">
                <tables-standard
                  :height=530
                  :headers="eventsTable.headings"
                  :items="eventsTable.data"
                />
              </skeletons-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
h1{
  color: #a09c94;
  font-family: sans-serif;
}
</style>

<script>
import { mapActions } from 'vuex';
export default {
  data: () => ({
    groupsTableLoading: false,
    eventsTableLoading: false,
    addEventDialog: false,
    createEventName: null,
    createEventStartLocalTime: null,
    createEventEndLocalTime: null,
    createEventErrors: [],
    createEventLoading: false,
    groupsTable: {
      headings: [
          { text: 'Group Name', align: 'start', value: 'name' },
          { text: 'Terminal Username', align: 'start', value: 'terminalUsername' },
          { text: 'Terminal Password', align: 'start', value: 'terminalPassword' },
      ],
      data: []
    },
    eventsTable: {
      headings: [
        { text: 'Event Name', align: 'start', value: 'name' },
        { text: 'Year', align: 'start', value: 'year' },
        { text: 'Payment Profile', align: 'start', value: 'paymentProfile' },
        { text: 'Printing Profile', align: 'start', value: 'printingProfile' },
        { text: 'Show in POS', align: 'start', value: 'showInPos' },
        { text: 'Discount/Surcharge %', align: 'start', value: 'discountSurcharge' },
        { text: 'Event Start', align: 'start', value: 'eventStartLocalTime' },
        { text: 'Event End', align: 'start', value: 'eventEndLocalTime' },
      ],
      data: []
    }
  }),
  created() {
    this.getAllGroups()
    this.getAllEvents()
  },
  methods: {
    ...mapActions('admin', ['getGroups', 'getEvents', 'createEvent']),
    getAllGroups() {
      this.groupsTableLoading = true
      this.getGroups().then(response => {
        this.groupsTable.data = response.map(r => {
          r['terminalPassword'] = 'BABAHWTY'
          return r
        })
        this.groupsTableLoading = false
      })
    },
    getAllEvents() {
      this.eventsTableLoading = true
      this.getEvents().then(response => {
        this.eventsTable.data = response
        this.eventsTableLoading = false
      })
    },
    createNewEvent() {
      this.createEventErrors = []
      this.createEventLoading = true
      const payload = {
        "name": this.createEventName, 
        "event_start_local_time": this.createEventStartLocalTime,
        "event_end_local_time": this.createEventEndLocalTime
      }
      this.createEvent(payload).then(response => {
        console.log(response)
        this.addEventDialog = false
        this.createEventLoading = false
        this.getAllEvents()
      }).catch(err => {
        this.createEventErrors = err.response.data.errors
        this.createEventLoading = false
      })
    }
  }
}
</script>